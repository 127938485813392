<template>
    <v-container>
        <v-row>
            <v-col class="pt-0">
                <h2 class="primary--text"> Actualités </h2>
            </v-col>
        </v-row>

        <!-- Quick search and pagination -->
        <v-row class="pb-4" align="center" no-gutters>
            <v-col cols="12" md="2">
                <v-text-field v-model="search.quickSearch" @keydown.enter="fetchActualities(true)" class="mr-4" label="Recherche..." dense hide-details outlined clearable>
                    <template v-slot:append>
                        <v-icon color="grey" @click="fetchActualities(true)"> fas fa-search </v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="5" class="d-flex align-center">
                <v-btn v-if="isEditMode" @click="displayAdvanceSearch = !displayAdvanceSearch" color="app-blue" outlined depressed>
                    <v-icon v-if="!displayAdvanceSearch" left x-small> fas fa-plus </v-icon>
                    <v-icon v-if="displayAdvanceSearch" left x-small> fas fa-minus </v-icon>
                    de filtres
                </v-btn>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn small text class="grey lighten-2 intro-counters ml-4" v-on="on">
                            {{ actualitiesCount }}
                        </v-btn>
                    </template>
                    Nombre total d'actualités
                </v-tooltip>

                <ActualityCreationDialog v-if="isEditMode" @created="onActualityCreated">
                    <template v-slot:activator="{ on: dialog }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn class="ml-4" color="primary" small v-on="{ ...dialog, ...tooltip }">
                                    Ajouter
                                </v-btn>
                            </template>
                            Créer une nouvelle actualité
                        </v-tooltip>
                    </template>
                </ActualityCreationDialog>
            </v-col>

            <v-col cols="12" md="5" class="text-right">
                <template v-if="isEditMode">
                    <v-select v-model="order" class="sort-pagination d-inline-flex intro-sort" label="Trier par" :menu-props="{ bottom: true, offsetY: true }" :items="actualitiesSortItems" background-color="white" @change="fetchActualities(true)" hide-details outlined dense>
                        <template v-slot:append-outer>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2 intro-sort-direction">
                                        <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                            fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <template v-if="by === 'asc'">
                                    Croissant
                                </template>
                                <template v-if="by === 'desc'">
                                    Décroissant
                                </template>
                            </v-tooltip>

                            <v-pagination v-model="page" @input="fetchActualities(true, false)" :length="totalPages" :total-visible="7" class="pagination intro-pagination" color="primary" />
                        </template>
                    </v-select>
                </template>
                <template v-else>
                    <v-pagination v-model="page" @input="fetchActualities(true, false)" :length="totalPages" :total-visible="7" class="pagination intro-pagination" color="primary" />
                </template>
            </v-col>
        </v-row>

        <!-- Advance search -->
        <v-expand-transition>
            <v-sheet v-show="displayAdvanceSearch" color="grey lighten-3 mb-4" rounded>
                <div class="pa-4">
                    <div class="d-flex">
                        <table>
                            <tr>
                                <td>
                                    <ProgramAutocomplete v-model="search.programs" @input="fetchActualities(true)" ref="programAutocomplete" label="Programmes" class="mr-2" flexGrow0 noDynamicSearch multiple />
                                </td>
                            </tr>
                        </table>

                        <v-divider vertical class="mx-4" />

                        <table>
                            <tr>
                                <td class="pl-4">
                                    <div class="d-flex align-center">
                                        <v-switch v-model="search.genericsOnly" @change="fetchActualities(true)" class="shrink pt-0 mt-0 ml-4 mr-2" label="Actualités génériques" dense hide-details />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="pl-4">
                                    <v-switch v-model="search.visibleOnly" @change="fetchActualities(true)" class="shrink pt-0 mt-0 ml-4 mr-2" label="Actualités diffusées seulement" dense hide-details />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </v-sheet>
        </v-expand-transition>

        <v-row v-if="actualities.length > 0">
            <v-col cols="12" lg="4" xl="3" v-for="actuality of actualities" :key="actuality.id">
                <ActualityCard :ref="`actuality-${actuality.id}`" :initActuality="actuality" imageHeight="150px" :allowModification="isEditMode" @updated="fetchActualities(false, false)" @deleted="fetchActualities(false, false)" />
            </v-col>
        </v-row>

        <v-row v-if="actualities.length === 0">
            <v-col class="text-center">
                <h1 class="font-weight-regular">
                    Aucune Actualité
                </h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import actualitiesMixin from '../mixins/actualities.js';
import ActualityCard from '../components/actualities/ActualityCard.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';
import ActualityCreationDialog from '../components/actualities/ActualityCreationDialog.vue';

export default {
    name: 'Actualities',

    mixins: [actualitiesMixin],

    components: {
        ActualityCard,
        ProgramAutocomplete,
        ActualityCreationDialog
    },

    data: () => ({
        displayAdvanceSearch: false,

        search: {
            quickSearch: null,
            visibleOnly: null,
            programs: null,
            genericsOnly: null
        },

        page: 1,
        limit: 10,
        order: 'created',
        by: 'desc',

        resetPage: false, // wether or not to reset the page to 1 when fetching the next items

        actualities: [],
        actualitiesCount: 0,
        totalPages: 0,
        disableNavigation: false,
        actualitiesSortItems: [
            { text: 'Date de création', value: 'created' },
            { text: 'Fin de diffusion', value: 'endDate' },
            { text: 'Début de diffusion', value: 'startDate' }
        ]
    }),

    methods: {
        buildQuery() {
            const query = {
                page: this.page,
                limit: this.limit,
                orderBy: `${this.order}:${this.by}`,
                include: 'attachments,targetCodes'
            };
            if (this.search.quickSearch) {
                query.quickSearch = this.search.quickSearch;
            }
            if (this.search.programs && this.search.programs.length > 0) {
                query.programsOnlyFilter = this.search.programs.join(',');
            }
            if (this.search.genericsOnly) {
                query.context = 'buyers';
            }
            if (this.search.visibleOnly) {
                query.enableDateRange = '1';
                query.publish = '1';
            }
            return query;
        },

        buildDisplayQuery() {
            const query = {};
            if (this.page !== 1) {
                query.page = this.page;
            }
            if (this.search.quickSearch) {
                query.quickSearch = this.search.quickSearch;
            }
            if (this.search.visibleOnly) {
                query.visibleOnly = this.search.visibleOnly;
            }
            if (this.search.genericsOnly) {
                query.genericsOnly = this.search.genericsOnly;
            }
            if (this.search.programs && this.search.programs.length > 0) {
                query.programs = this.search.programs.join(',');
            }
            if (this.order !== 'program.title') {
                query.order = this.order;
                query.by = this.by;
            }
            return query;
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.limit = parseInt(query.limit) || 10;
            this.order = query.order ? query.order : this.order;
            this.by = query.by ? query.by : this.by;
            this.search.quickSearch = query.quickSearch || null;
            this.search.visibleOnly = query.visibleOnly || null;
            this.search.quickSearch = query.quickSearch || null;
            this.search.programs = query.programs ? query.programs.split(',').map((p) => parseInt(p)) : [];
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        },

        async fetchActualities(push, resetPage = true, actualityId = null) {
            try {
                this.disableNavigation = true;
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (push && this.doPush(this.buildDisplayQuery())) {
                    this.$router.push({ query: this.buildDisplayQuery() });
                }

                const query = this.buildQuery();
                if (this.$store.state.application.user.type === 'buyer') {
                    query.dashboard = 1;
                    query.programs = this.$store.state.application.sales.map((s) => s.program.id);
                }

                const { actualities, count, totalPages, err } = await this.repos.actualities.getActualities(query);
                if (err) {
                    throw new Error(err);
                } else {
                    this.actualities = actualities;
                    this.actualitiesCount = count;
                    this.totalPages = totalPages;

                    if (actualityId) {
                        this.$nextTick(() => {
                            const key = `actuality-${actualityId}`;
                            this.$refs[key][0].openEditionDialog();
                        });
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.disableNavigation = false;
                this.setLoading(false);
            }
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchActualities(true);
        },

        onActualityCreated(data) {
            this.fetchActualities(false, false, data.actualityId);
        }
    },

    computed: {
        isEditMode() {
            return this.$store.state.application.user.type === 'user';
        }
    },

    watch: {
        $route(to) {
            if (!this.disableNavigation) {
                this.parseQuery(to.query);
                this.fetchActualities(false);
            }
        }
    },

    created() {
        this.parseQuery(this.$route.query);
        this.fetchActualities(false, false);

        this.$nextTick(() => {
            this.$refs.programAutocomplete.fetchPrograms({ limit: 1000 });
        });
    }
};
</script>

<style lang="scss" scoped>
.pagination::v-deep .v-pagination {
    width: initial;
    max-width: initial;

    .v-pagination__navigation:last-child {
        margin-right: 0;
    }
}

.sort-pagination::v-deep {
    .v-input__control {
        max-width: 300px;
    }

    .v-input__append-outer {
        margin: 0 !important;
    }
}
</style>